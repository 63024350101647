import { delay } from "framer-motion";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";

function Tab3({ socket, teadata }) {
  const [clickedButton, setClickedButton] = useState(null);
  const [clickedButton2, setClickedButton2] = useState(false);
  const [clickedButton3, setClickedButton3] = useState(false);
  const [clickedButton4, setClickedButton4] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const LockButtonClick = (type, index, status) => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const nextStatus = status ? "Off" : "On";

    Swal.fire({
      title: `${type} ${index + 1}`,
      text: `${
        status ? "On" : "Off"
      } 상태입니다. ${nextStatus}로 전환하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${nextStatus}로 전환`,
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        const button = `${type.toLowerCase()}${
          index + 1
        }_${nextStatus.toLowerCase()}`;

        const data = {
          function: "clean",
          value: {
            button,
          },
        };
        setIsLoading(true);
        socket.send(JSON.stringify(data));
        socket.onmessage = (event) => {
          const tea1Message = event.data;
          console.log("서버에서 받은 메시지:", tea1Message);
          setIsLoading(false);
        };
        Swal.fire(
          "전환 완료!",
          `${type} ${index + 1}가 ${nextStatus}로 전환되었습니다.`,
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        console.log(`${type} ${index + 1}가 ${nextStatus}로 전환 실행됨`);
      }
    });
  };

  const Topping1Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "topping1_on",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("오레오 추출중...");
  };

  const Topping2Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "topping2_on",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("로투스 추출중...");
  };

  const Topping3Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "topping3_on",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("topping3 추출중...");
  };

  const Topping4Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "topping4_on",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("topping4 추출중...");
  };

  const Topping5Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "topping5_on",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("topping5 추출중...");
  };

  const Topping6Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "topping6_on",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("topping6 추출중...");
  };

  const handleToDoor = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "tray_to_door",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("트레이1,3 도어로 보내기...");
  };

  const handleToWorkSpace = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "tray_to_workspace",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("트레이 작업공간으로...");
  };

  const handleMixieGripperOpenClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "mixie_gripper_open",
        value: 1,
      },
    };

    socket.send(JSON.stringify(data1));
    setMessage("Mixie Gripper Open...");
  };

  const handleMixieGripperCloseClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = { button: "mixie_gripper_close", value: 1 };

    socket.send(JSON.stringify(data1));
    setMessage("Mixie Gripper Close...");
  };

  const handlePortyGripperOpenClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: {
        button: "porty_gripper_open",
        value: 1,
      },
    };
    socket.send(JSON.stringify(data1));
    setMessage("Porty Gripper Open...");
  };

  const handlePortyGripperCloseClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }
    const data1 = {
      function: "clean",
      value: {
        button: "porty_gripper_close",
        value: 1,
      },
    };
    socket.send(JSON.stringify(data1));
    setMessage("Porty Gripper Close...");
  };

  return (
    <Box1>
      <BoxCategory style={{ marginTop: "16px" }}> TOPPING </BoxCategory>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {isLoading && <LoadingOverlay>업데이트 중...</LoadingOverlay>}
        <ButtonTopping
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={Topping1Click}
        >
          오레오
        </ButtonTopping>
        <ButtonTopping
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton4}
          onClick={Topping2Click}
        >
          로투스
        </ButtonTopping>
        <ButtonTopping
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={Topping3Click}
        >
          후르츠 씨리얼
        </ButtonTopping>
        <ButtonTopping
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={Topping4Click}
        >
          미니볼 씨리얼
        </ButtonTopping>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ButtonTopping
          style={{ margin: "0px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={Topping5Click}
        >
          레인보우 <br /> 스프링클
        </ButtonTopping>
        <ButtonTopping
          style={{ margin: "0px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={Topping6Click}
        >
          초콜릿 컬
        </ButtonTopping>
        <Button4
          style={{ margin: "0px 10px 10px 0px" }}
          clicked={clickedButton4}
        >
          ㅤ
        </Button4>
        <Button4
          style={{ margin: "0px 10px 10px 0px" }}
          clicked={clickedButton4}
        >
          ㅤ
        </Button4>
      </div>
      <BoxCategory style={{ marginTop: "10px", marginBottom: "6px" }}>
        {" "}
        TRAY{" "}
      </BoxCategory>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <ButtonTray
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handleToDoor}
        >
          보내기
        </ButtonTray>

        {teadata && teadata.isEspressoOn ? (
          teadata.isEspressoOn.map((status, index) => (
            <LockButton
              key={`espresso-${index}`}
              onClick={() => LockButtonClick("Espresso", index, status)}
            >
              Espresso {index + 1}: {status ? "On" : "Off"}
            </LockButton>
          ))
        ) : (
          <div>Loading Espresso Status...</div>
        )}

        {teadata && teadata.isGrinderOn ? (
          teadata.isGrinderOn.map((status, index) => (
            <LockButton
              key={`grinder-${index}`}
              onClick={() => LockButtonClick("Grinder", index, status)}
            >
              Grinder {index + 1}: {status ? "On" : "Off"}
            </LockButton>
          ))
        ) : (
          <div>Loading Grinder Status...</div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ButtonTray
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handleToWorkSpace}
        >
          가져오기(30초 후)
        </ButtonTray>

        {teadata && teadata.isPortaOn ? (
          teadata.isPortaOn.map((status, index) => (
            <LockButton
              key={`porta-${index}`}
              onClick={() => LockButtonClick("Porta", index, status)}
            >
              Porta {index + 1}: {status ? "On" : "Off"}
            </LockButton>
          ))
        ) : (
          <div>Loading Porta Status...</div>
        )}
      </div>
      <div>
        <BoxCategory style={{ marginTop: "10px", marginBottom: "6px" }}>
          {" "}
          GRIPPER{" "}
        </BoxCategory>

        <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handleMixieGripperOpenClick}
        >
          MIXIE GRIPPER OPEN
        </ButtonGripper>
        <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handleMixieGripperCloseClick}
        >
          MIXIE GRIPPER CLOSE
        </ButtonGripper>
        <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handlePortyGripperOpenClick}
        >
          PORTY GRIPPER OPEN
        </ButtonGripper>
        <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handlePortyGripperCloseClick}
        >
          PORTY GRIPPER CLOSE
        </ButtonGripper>
      </div>
    </Box1>
  );
}

export default Tab3;

const Box1 = styled.div`
  display: flex;
  align-items: center;
  width: 1046px;
  height: 730px;
  background: #f0f0f0;
  flex-direction: column;
`;

const BoxCategory = styled.div`
  width: 990px;
  height: 32px;
  background: linear-gradient(
    90deg,
    #d9d9d9 1.57%,
    rgba(217, 217, 217, 0) 100%
  );
  opacity: 0.8;
  border-radius: 7px;
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-top: 28px;
  padding-left: 10px;
`;

const ButtonGripper = styled.button`
  width: 240px;
  height: 100px;
  background-color: #ffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 24px;
  color: black;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

const Button1 = styled.button`
  font-size: 28px;
  color: black;
  margin: 0 10px 10px 0;
  width: 240px;
  height: 110px;
  border-radius: 8px;
  transition: 0.5s;
  background-color: #ffffff;
  border: none;
  font-weight: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  background-image: url(${(props) => props.imageUrl});
  background-size: cover;

  &:hover {
    cursor: pointer;
    border: 1px solid #f84d27;
  }
`;

const ButtonTopping = styled.button`
  width: 240px;
  height: 100px;
  background-color: #ffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 28px;
  color: black;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

const ButtonTray = styled.button`
  width: 240px;
  height: 100px;
  background-color: #ffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 28px;
  color: black;
  transition: 0.5s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

const Button4 = styled.button`
  width: 240px;
  height: 100px;
  background-color: #f3f3f3;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 28px;
  color: black;
  transition: 0.5s;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;
const LockButton = styled.button`
  width: 177px;
  height: 100px;
  background-color: #ffffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 20px;
  color: black;
  transition: 0.5s;

  margin: 10px 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5rem;
  z-index: 1000;
`;
