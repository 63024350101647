import React, { useState, useEffect } from "react";
import styled from "styled-components";

function GridButton({ onClick, valueObject, isActive }) {
  return (
    <Button1
      imageUrl={valueObject.image}
      onClick={() => onClick(valueObject)}
      isActive={isActive}
    >
      {valueObject.name}
    </Button1>
  );
}

function Tab1({ socket, setMessage, message }) {
  const [buttonStates, setButtonStates] = useState({});
  const [isHotWaterActive, setHotWaterActive] = useState(false); // 온수 버튼 상태
  const [isMilkActive, setMilkActive] = useState(false); // 우유 버튼 상태

  const buttonValues1 = [
    [
      {
        id: "syrup1",
        name: "아이스티",
        message: "아이스티 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup1", value: 1 },
        },
      },
      {
        id: "syrup6",
        name: "초코",
        message: "초코 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup6", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup2",
        name: "레몬",
        message: "레몬 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup2", value: 1 },
        },
      },
      {
        id: "syrup7",
        name: "딸기",
        message: " 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup7", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup3",
        name: "화이트 뱅쇼",
        message: "화이트 뱅쇼 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup3", value: 1 },
        },
      },
      {
        id: "syrup8",
        name: "바닐라",
        message: " 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup8", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup4",
        name: "레드 뱅쇼",
        message: "레드 뱅쇼 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup4", value: 1 },
        },
      },
      {
        id: "syrup9",
        name: "시나몬",
        message: "시나몬 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup9", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup5",
        name: "밀크티",
        message: "연유 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup5", value: 1 },
        },
      },
      {
        id: "soda_left_on",
        name: "탄산수 좌",
        message: "탄산수 좌측 추출중...",
        data: {
          function: "clean",
          value: { button: "soda_left_on", value: 1 },
        },
      },
    ],
  ];

  const buttonValues2 = [
    [
      {
        id: "syrup10",
        name: "ㅤ",
        message: "클래식 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup10", value: 1 },
        },
      },
      {
        id: "syrup15",
        name: "ㅤ",
        message: "레몬 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup15", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup11",
        name: "ㅤ",
        message: "녹차 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup11", value: 1 },
        },
      },
      {
        id: "syrup16",
        name: "ㅤ",
        message: "딸기 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup16", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup12",
        name: "ㅤ",
        message: "블루 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup12", value: 1 },
        },
      },
      {
        id: "syrup17",
        name: "ㅤ",
        message: "홍차 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup17", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup13",
        name: "ㅤ",
        message: "바닐라 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup13", value: 1 },
        },
      },
      {
        id: "syrup18",
        name: "ㅤ",
        message: "빈 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup18", value: 1 },
        },
      },
    ],
    [
      {
        id: "syrup14",
        name: "ㅤ",
        message: "메론 시럽 추출중...",
        data: {
          function: "clean",
          value: { button: "syrup14", value: 1 },
        },
      },
      {
        id: "soda_right_on",
        name: "탄산수 우",
        message: "탄산수 우측 추출중...",
        data: {
          function: "clean",
          value: { button: "soda_right_on", value: 1 },
        },
      },
    ],
  ];

  const handleButtonClick = (valueObject) => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const isActive = buttonStates[valueObject.id];
    const dataToSend = {
      ...valueObject.data,
      value: { ...valueObject.data.value, value: isActive ? 0 : 1 },
    };
    socket.send(JSON.stringify(dataToSend));
    setButtonStates((prevState) => ({
      ...prevState,
      [valueObject.id]: !isActive,
    }));
  };

  const handleHotWaterClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: { button: "hot_water_on", value: isHotWaterActive ? 0 : 1 },
    };

    socket.send(JSON.stringify(data1));
    setMessage("온수 추출중...");
    setHotWaterActive(!isHotWaterActive);
  };

  const handleMilkClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data2 = {
      function: "clean",
      value: { button: "milk_on", value: isMilkActive ? 0 : 1 },
    };
    socket.send(JSON.stringify(data2));
    setMessage("우유 추출중...");
    setMilkActive(!isMilkActive); // 상태 변경
  };

  const handlePauseSyrupClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      function: "clean",
      value: { button: "syrup_stop", value: 1 },
    };

    socket.send(JSON.stringify(data1));

    setMessage("시럽 멈추기 버튼이 클릭되었습니다.");
    // Set all syrup-related button states to false (inactive)
    setButtonStates((prevState) => {
      const updatedStates = {};
      for (const key in prevState) {
        if (key.startsWith("")) {
          updatedStates[key] = false;
        } else {
          updatedStates[key] = prevState[key];
        }
      }
      return updatedStates;
    });
    setHotWaterActive(false);
    setMilkActive(false);
  };

  const createGrid = (buttonValues) =>
    buttonValues.map((rowValueArray, rowIndex) => (
      <div key={rowIndex}>
        {rowValueArray.map((valueObject, colIndex) => (
          <GridButton
            key={colIndex}
            valueObject={valueObject}
            onClick={handleButtonClick}
            isActive={buttonStates[valueObject.id]}
          />
        ))}
      </div>
    ));

  return (
    <Box>
      <div style={{ display: "flex", marginTop: "28px" }}>
        <div style={{ marginLeft: "8px", marginRight: "20px" }}>
          {createGrid(buttonValues1)}
        </div>
        <div>{createGrid(buttonValues2)}</div>
      </div>

      <div style={{ marginTop: "-20px", height: "150px" }}>
        <Button2 onClick={handleHotWaterClick} isActive={isHotWaterActive}>
          온수
        </Button2>
        <Button2 onClick={handleMilkClick} isActive={isMilkActive}>
          우유
        </Button2>

        <Button3 onClick={handlePauseSyrupClick}>시럽 멈추기</Button3>
      </div>
    </Box>
  );
}

export default Tab1;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1046px;
  height: 730px;
  background: #f0f0f0;
  flex-direction: column;
`;

const Button1 = styled.button`
  font-size: 28px;
  color: black;
  margin: 0 12px 12px 0;
  width: 220px;
  height: 100px;
  border-radius: 8px;
  transition: 0.5s;
  background-color: ${(props) => (props.isActive ? "#F84D2763" : "#ffffff")};
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: ${(props) => (props.isActive ? "#F84D2763" : "#fff8f6")};
    border: 1px solid #f84d27;
  }
`;

const Button2 = styled.button`
  background-color: ${(props) => (props.isActive ? "#F84D2763" : "#ffffff")};
  font-size: 28px;
  color: black;
  border: none;
  margin: 30px 0 0 12px;
  border-radius: 8px;
  width: 220px;
  height: 100px;
  transition: 0.5s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  font-weight: normal;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: ${(props) => (props.isActive ? "#F84D2763" : "#808080")};
    border: 1px solid #000000;
  }
`;

const Button3 = styled.button`
  background-color: #fdcabe;
  font-size: 28px;
  color: black;
  border: none;
  margin-left: 20px;
  border-radius: 8px;
  width: 220px;
  height: 100px;
  transition: 0.5s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  font-weight: normal;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fba693;
    border: 2px solid #000000;
  }
`;

const Label1 = styled.label`
  font-size: 28px;
  margin-left: 40px;
  font-weight: bold;
`;

const Label2 = styled.label`
  font-size: 28px;
  margin-left: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  font-size: 26px;
  background-color: white;
  width: 73px;
  height: 53px;
  padding-left: 15px;
  border-radius: 12px;
  margin-left: 5px;

  border: none;
`;
